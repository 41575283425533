import { buildUrlManager } from "@coveo/headless";

/**
 * Search parameters, defined in the url's hash, should not be restored until all components are registered.
 *
 * Additionally, a search should not be executed until search parameters are restored.
 *
 * @param engine - A headless search engine instance.
 * @returns An unsubscribe function to remove attached event listeners.
 */
export function bindUrlManager(engine) {
  const fragment = () => {
    return window.location.hash.slice(1).replaceAll('-&-', '-%26-');
  }

  const urlManager = buildUrlManager(engine, {
    initialState: { fragment: fragment() },
  });
  const onHashChange = () => {
    urlManager.synchronize(fragment());
  };

  window.addEventListener("hashchange", onHashChange);
  const unsubscribeManager = urlManager.subscribe(() => {
    const hash = `#${urlManager.state.fragment}`;
    window.history.pushState(null, document.title, hash);
  });

  return () => {
    window.removeEventListener("hashchange", onHashChange);
    unsubscribeManager();
  };
}
